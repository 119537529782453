<template>
  <div>
    <v-row>
      <!-- <v-col> -->
      <!-- </v-col> -->
      <v-col md="12" sm="6" cols="12">
        <v-card class="d-flex justify-end mb-6" flat tile>
          <v-breadcrumbs :items="breadcrumbs" large>
            <template v-slot:divider>
              <v-icon>{{ icons.mdiSlashForward }} </v-icon>
            </template>
          </v-breadcrumbs>
        </v-card>

        <v-card>
          <v-img class="white--text align-end" :src="defaultBackground()" height="200px" />
          <v-card-text class="position-relative">
            <!-- User Avatar -->
            <v-avatar size="90" color="white" class="avatar-center">
              <v-img :src="showPhoto()"></v-img>
            </v-avatar>
            <!-- Title, Subtitle & Action Button -->
            <div class="d-flex justify-space-between flex-wrap pt-16">
              <div class="me-2 mb-2">
                <v-card-title class="pt-0 px-0">
                  {{ transaksi.visa.nama_depan }}
                  {{ transaksi.visa.nama_belakang }}
                </v-card-title>
                <v-card-subtitle class="text-xs pa-0"> {{ transaksi.visa.no_telp }} </v-card-subtitle>
              </div>
              <div class="me-2 mb-2">
                <v-card-title class="pt-0 px-0">
                  {{ transaksi.visa.jenis_visa }}
                </v-card-title>
                <v-card-subtitle class="text-xs pa-0">
                  Visa Expired : <strong>{{ transaksi.visa.tgl_kadaluarsa | date }}</strong>
                </v-card-subtitle>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-text>
            <v-alert type="info"
              ><span> <strong>ORDER</strong> </span><br />
              <span v-show="transaksi.visa.jenis_permohonan != ''">
                PEMBUATAN JENIS PERMOHONAN VISA:<strong>
                  {{ transaksi.visa.jenis_permohonan }} ( {{ transaksi.visa.detail }} -
                  {{ transaksi.visa.indeks }} )</strong
                >
              </span>
            </v-alert>

            <v-col md="12" cols="12">
              <v-expansion-panels>
                <v-expansion-panel v-for="(hb, index) in biaya" :key="index">
                  <v-expansion-panel-header>
                    {{ hb.created_at | date }}
                    <span sm="2" offset-sm="2" md="2" offset-md="0">
                      <v-chip class="ma-2" color="error" text-color="white"> unpaid</v-chip>
                    </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-alert type="success">
                      <label>TRANSAKSI</label><small class="ml-2"><strong>(IDR)</strong></small>
                    </v-alert>

                    <!-- <div class="form-group">
                      <label>TRANSAKSI</label><small class="ml-2"><strong>(IDR)</strong></small>
                    </div> -->
                    <br />
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">FEE TYPE</th>
                            <th class="text-left">PRICE</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(b, index) in biayarupiah" :key="index" v-if="b.id_transaksi == hb.id_transaksi">
                            <!-- <span v-if="b.id_transaksi == hb.id_transaksi"> -->
                            <td>{{ b.jenis_biaya }}</td>
                            <td>{{ b.harga | currency }}</td>
                            <!-- </span> -->
                          </tr>
                          <tr>
                            <td>
                              <b>TOTAL</b>
                            </td>
                            <td
                              v-for="(tb, index) in totalbiaya"
                              :key="index"
                              v-if="tb.id_transaksi == hb.id_transaksi"
                            >
                              <strong> {{ tb.total_biaya | currency }}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <hr />
                    <v-alert type="success">
                      <label>TRANSAKSI</label><small class="ml-2"><strong>(USD)</strong></small>
                    </v-alert>
                    <br />
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">FEE TYPE</th>
                            <th class="text-left">PRICE</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(bd, index) in biayadollar" :key="index" v-if="bd.id_transaksi == hb.id_transaksi">
                            <!-- <span v-if="b.id_transaksi == hb.id_transaksi"> -->
                            <td>{{ bd.jenis_biaya }}</td>
                            <td>{{ bd.harga | currency }}</td>
                            <!-- </span> -->
                          </tr>
                          <tr>
                            <td>
                              <b>TOTAL</b>
                            </td>
                            <td
                              v-for="(tb, index) in totalbiayadollar"
                              :key="index"
                              v-if="tb.id_transaksi == hb.id_transaksi"
                            >
                              <strong> {{ tb.total_biaya_dollar | dollar_currency }}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <hr />
                    <form @submit.prevent="updatePembayaran" enctype="multipart/form-data">
                      <v-container fluid>
                        <v-checkbox
                          label="CASH (MAKE SURE THE CLIENT HAS PAYED !)"
                          v-model="transaksi.tipe_bayar"
                          color="success"
                          value="tunai"
                        ></v-checkbox>
                        <!-- <v-col cols="12"> -->
                        <v-btn type="submit" color="success" class="me-3 mt-4"> Bayar </v-btn>
                        <!-- </v-col> -->
                      </v-container>
                    </form>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiDelete,
  mdiGreasePencil,
  mdiContentSave,
  mdiCheckBold,
  mdiCloudDownload,
  mdiSlashForward,
} from '@mdi/js'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Visas',
          disabled: false,
          href: '/admin/visas',
        },
        {
          text: 'Pembayaran',
          disabled: true,
        },
      ],
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
        mdiDelete,
        mdiGreasePencil,
        mdiContentSave,
        mdiCheckBold,
        mdiCloudDownload,
        mdiSlashForward,
      },

      biaya: [],
      biayarupiah: [],
      biayadollar: [],
      totalbiaya: [],
      totalbiayadollar: [],
      // jmldatavisa: [],

      transaksi: {
        tipe_bayar: 'tunai',
        visa: {},
        datatransaksi: {},
        bukti_pembayaran: '',
      },
    }
  },
  mounted() {
    this.$isLoading(true)

    setTimeout(() => {
      this.defaultBackground()
      this.getAllData()
      this.showPhoto()
      this.getBiaya()
      this.$isLoading(false)
    }, 2000)
  },

  methods: {
    updatePembayaran(e) {
      // console.log('ID TRANSAKSI : ' + this.transaksi.datatransaksi[0].id_transaksi)
      if (this.transaksi.tipe_bayar == 'tunai') {
        this.$isLoading(true)
        let uriPembayaran =
          process.env.VUE_APP_ROOT_API +
          `/api/transaksis/${this.$route.params.id_visa}/${this.transaksi.datatransaksi[0].id_transaksi}/bayartunai`
        // let uriPembayaran = `/api/admin/transaksis/${this.$route.params.id_visa}/${this.transaksi.datatransaksi[0].id_transaksi}/bayartunai`
        this.axios
          .post(uriPembayaran, this.transaksi)
          .then(response => {
            this.$isLoading(false)
            this.$swal.fire({
              title: 'Success',
              text: 'Pembayaran created successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })

            // this.$router.push({ name: 'users-index' })
            setTimeout(() => {
              this.$router.push({ name: 'visas-index' })
            }, 3000)
          })
          .catch(e => {
            this.$swal.fire({
              title: 'Error',
              text: 'Something went wrong, cant update visa!',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 2000,
            })
          })
      } else {
        this.$swal.fire({
          title: 'Error',
          text: 'Make sure the CASH checklist is clicked!',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2000,
        })
      }

      // e.preventDefault()
    },
    defaultBackground() {
      let imageDefault = process.env.VUE_APP_ROOT_API + `/images/berkas/background-berkas.png`
      return imageDefault
    },
    getAllData() {
      localStorage.getItem('token')
      let uriVisa = process.env.VUE_APP_ROOT_API + `/api/visas/${this.$route.params.id_visa}/show`
      this.axios.get(uriVisa).then(response => {
        this.transaksi.visa = response.data.datavisa
        this.transaksi.datatransaksi = response.data.datatransaksis
      })
    },
    getBiaya() {
      let uriEdit = process.env.VUE_APP_ROOT_API + `/api/visas/${this.$route.params.id_visa}/biayatransaksi`
      return this.axios.get(uriEdit).then(response => {
        console.log(response.data)
        // this.jmldatavisa = response.data.jmldatavisa
        this.biaya = response.data.datatransaksiidr
        this.biayarupiah = response.data.datatransaksiidrdetail
        this.biayadollar = response.data.datatransaksidollar.filter(
          v => v.harga != undefined || v.jenis_biaya != undefined,
        )
        this.totalbiaya = response.data.totaltransaksiidr
        this.totalbiayadollar = response.data.totaltransaksidollar
      })
    },
    showPhoto() {
      localStorage.getItem('token')

      if (typeof this.transaksi.visa.photo === 'string' && this.transaksi.visa.photo.length != 0) {
        if (this.transaksi.visa.photo.length > 100) {
          return this.transaksi.visa.photo
        }
        let imageClients =
          process.env.VUE_APP_ROOT_API + `/images/member/${this.transaksi.visa.id_member}/${this.transaksi.visa.photo}`
        return imageClients
      }
      let imageDefault = process.env.VUE_APP_ROOT_API + `/images/member/no-image.png`
      return imageDefault
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/plugins/vuetify/default-preset/preset/mixins.scss';

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 6px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}
.membership-pricing {
  text-align: center;
  sup {
    font-size: 3.75rem;
    top: 9px;
  }
}
</style>
